import styles from "./ThirdScreen.module.css"

import LessonBubble from "../components/steps/Step"
import MinimalistBox from "../components/house/House"
const ThirdScreen=()=>{

return <>
<div className={styles.description}>מה כוללות סדנאות  BABY.LOVE ?</div>
<div className={styles.row}>
<MinimalistBox title="ראיית העולם דרך עיני התינוק" description=" גלו את העולם המרתק כפי שהתינוק רואה, חווה ומבין אותו. נזהה את הסימנים, הצרכים והרגשות המועברים דרך שפת גופו של התינוק ונעמיק בתקשורת מקרבת.
"/>
<MinimalistBox title="חוויות סנסומוטוריות" description="נגלה יחד איך לעודד את ההתפתחות החושית והמוטורית של התינוק באמצעות המגע.
וחשיפה למגוון משחקים סנסומוטורים. המגע שבעיסוי וחשיפה למשחקים סנסומוטורים הם כלים חשובים להתפתחות הסנסומוטורית של התינוק המאפשר לו להכיר ולהבין תחושות שונות כמו ליטוף, לחיצה עדינה, הפקת צלילים וטקסטורות שונות. דרך המשחק הסנסומוטורי נעודד את התינוק לחקר ולמידה על עצמו ועל העולם סביבו"/>
<MinimalistBox title="התפתחות קוגנטיבית ותקשורתית" description="הבנת השפה היחודית של התינוק ותקשורת מקרבת.
נגלה יחד איך התינוק מחקשר את רגשותיו, צרכיו ומחשבותיו דרך סימני גוף,קולות ,מגוון הבעות פנים וג'סטות.
תוכלו לראות את העולם דרך עיניו ובכך להבין בצורה עמוקה יותר מה הוא מרגיש ומה הוא צריך. נלמד גם איך לתמוך בהתפתחות הקוגניטיבית והתקשורתית של התינוק על ידי שימוש באביזרים, משחקיים ייחודיים התורמים להבנה,והפקת שפה מוקדמת."/>
</div>
<div className={styles.row}>
<MinimalistBox title="התפתחות רגשית-חברתית" description="כל אינטרקציה, מגע, שפת גוף ואינטונציה שהתינוק חווה מהוריו משפיעים על הביטחון הרגשי והפיזי שלו. תחושות אלה משפיעות על גיבוש תפיסת העולם שלו לגבי עצמו,המרחב והסביבה ובפרט על טיב מערכת היחסים הנרקמת עם הוריו .
נלמד איך כל תגובה הורית משפיעה ומעצבת את אישיותו ותחושותיו של התינוק.
נחשף יחד בחוויות מרגשות המסייעות לתינוק לפתח יכולות רגשיות וחברתיות מיטיבות, ובכך ליצור מערכות יחסים מבוססת אהבה ואמון."/>
<MinimalistBox title="התפתחות המשחק" description=" גלו את מלוא הפוטנציאל!
המשחק בעולמו של התינוק הוא שפה בפני עצמה וחלק משמעותי בכל האספקטים של ההתפתחות. שילוב המשחק בדרך איכותית ומותאמת לתינוק ולשלב ההתפתחותי שלו מעודדת את תחושת הערך , המשמעות והמסוגלות האישית שלו ומחזקת את מערכת היחסים בינו לבין הוריו. יחד נתנסה במשחקים מותאמי גיל נלמד ונבין איך לפתח את המיומנות הרגשית,הסנסומוטורית,קוגנטיבית,התקשורתית,השפתית והחברתית של התינוק דרך המשחק 
נתנסה במשחקים מותאמי גיל ונלמד יחד איך לפתח את המיומנויות הרגשיות, סנסומוטוריות, קוגניטיביות, תקשורתיות, שפתיות וחברתיות של התינוק."/>
<MinimalistBox title="כוחו של ההורה במערכת היחסים" description=""/>
</div>

</>


}
export default ThirdScreen